// https://igorescobar.github.io/jQuery-Mask-Plugin/
require('../../../node_modules/jquery-mask-plugin/dist/jquery.mask.js');

$(function() {
	// App input masks
	$('[data-input="big-number"]').mask("###0", { reverse: true, clearIfNotMatch: true });
	$('[data-input="small-number"]').mask("##0", { reverse: true, clearIfNotMatch: true });
	$('[data-input="money"]').mask("#.##0,00", { reverse: true });
	$('[data-input="money-with-symbol"]').mask("R$ #.##0,00", { reverse: true });
	$('[data-input="decimal"]').mask("#.##0,00", { reverse: true });
	$('[data-input="decimal-small"]').mask("#.#0", { reverse: true, clearIfNotMatch: true });
	$('[data-input="decimal-limited"]').mask("##0.00", { reverse: true, clearIfNotMatch: true });
	$('[data-input="number"]').mask("#.##0", { reverse: true });
	$('[data-input="date"]').mask("00/00/0000", { placeholder: "__/__/____", clearIfNotMatch: true });
	$('[data-input="time"]').mask("00:00", { placeholder: "  :  ", clearIfNotMatch: true });
	$('[data-input="datetime"]').mask("00/00/0000 00:00", { placeholder: "  /  /       :  ", clearIfNotMatch: true });
	$('[data-input="cpf"]').mask("000.000.000-00", { reverse: true });
	$('[data-input="cnpj"]').mask("00.000.000/0000-00", { reverse: true });
	$('[data-input="cep"]').mask("00000-000", { reverse: true });
	$('[data-input="cel"]').mask("(00) 00000-0000");
	$('[data-input="slug"]').mask("A", {
		translation: {
			'A': {
				pattern: /[a-z0-9-]/,
				recursive: true
			}
		}
	});

	// Number digits phone
	const nonoDigito = function (val) {
		return val.replace(/\D/g, '').length === 11 ? "(00) 00000-0000" : "(00) 0000-00000";
	};
	const phoneOptions = {
		onKeyPress: function (val, e, field, options) {
			field.mask(nonoDigito.apply({}, arguments), options);
		}
	};

	// Creating phone mask
	$('[data-input="phone"]').mask(nonoDigito, phoneOptions);
});
