/**
 * --------
 * SNACKBAR
 * --------
 */
$(function() {

	// Checking if container exists
	if (!$('#snackbar').length) {
		$('body').prepend('<div id="snackbar" class="snackbar"></div>');
	}

	// Running function
	window.snackBar = function() {
		let snackItems = $('#snackbar').find('.snackbar__item');

		snackItems.each(function(i) {
			let bar = $(this);
			// Showing snackbar
			setTimeout(function() {

				bar.animate({ opacity: 1, right: 280 }, 250)

				// Automatic removing snackbar
				setTimeout(function() {
					bar.animate({right: -500}, 150);
				}, i * 1000 + 15000);
			}, i * 500 + 500);

			// Remove on click (each)
			bar.on('click', function() {
				bar.animate({right: -500}, 150);
			});
		});
	}

	// Running function on page start
	snackBar();

	// Removing all items
	window.snackBarClear = function() {
		$('#snackbar .snackbar__item').remove();
	}

	// Adding item inside container
	window.SnackBarAdd = function(message) {
		$('#snackbar').prepend('<div class="snackbar__item"><p>' + message + '</p></div>');
	}
});
