$(function() {

	if ($('.filter-list-options').length) {
		// Evento to check all
		$('[data-filter-check-all]').on('click', function (e) {
			let rC = $(this).data('for');

			console.log('Filter list [' + rC + '] - to check all');

			let checkboxes = $('#' + rC).find('input[type="checkbox"]');

			checkboxes.each(function () {
				$(this).prop('checked', true);
			});

			e.preventDefault();
		});

		// Event to uncheck all
		$('[data-filter-uncheck-all]').on('click', function (e) {
			let rC = $(this).data('for');

			console.log('Filter list [' + rC + '] - to uncheck all');

			let checkboxes = $('#' + rC).find('input[type="checkbox"]');

			checkboxes.each(function () {
				$(this).prop('checked', false);
			});

			e.preventDefault();
		});

		// Expand/collapse panel
		$('[data-filter-toggle-height]').on('click', function () {
			let rC = $(this).data('for');
			let container = $('#' + rC);

			console.log('Filter list [' + rC + '] - to toggle height');

			if (container.hasClass('expand')) {
				container.removeClass('expand');
				$(this).find('span').text('Expandir');
			} else {
				container.addClass('expand');
				$(this).find('span').text('Recolher');
			}
		});
	}
});
