$(function() {

	$.fn.closeModal = function(options) {
		const defaults = {
			actionClass: 'opened',
			completeClass: 'completed',
			modalClass: 'modal',
			darkBoxClass: 'dark-box',
			fadeTime: 500
		};
		const $this = this;
		const settings = $.extend({}, defaults, options);

		// Close modal
		$this.closest('.' + settings.modalClass).removeClass(settings.actionClass);

		// Close dark box
		$this.closest('.' + settings.darkBoxClass).fadeOut(settings.fadeTime);

		console.log('Modal closed');
	}

	$.fn.modal = function(options) {
		let defaults = {
			for: this.data('for'),
			actionClass: 'opened',
			completeClass: 'completed',
			fadeInTime: 'fast',
			method: this.data('method') ? this.data('method') : 'GET',
			params: this.data(),
			onStart: function () {
			},
			onComplete: function () {
			}
		};
		let settings = $.extend({}, defaults, options);
		let modalItem = $('[data-modal-container="' + (settings.for.replace('#', '')) + '"]');

		console.log('Modal ' + settings.for + ' clicked');

		// Add class to modal on start
		settings.onStart.call();

		console.log('Modal started');

		// Check if modal is AjAX or internal
		if ((settings.for).slice(0, 4) === 'http') {
			const modalId = 'ajaxModal' + Math.random();
			const dBox = `
				<div class="dark-box" data-dark-box-id="${modalId}">
					<div class="modal modal-mobile">
						<div class="modal-container">
							<div class="modal-body" style="min-height:290px;">
								<div class="modal-close" data-modal-close data-method="remove" data-for="${modalId}">
									<i class="mdi">close</i>
								</div>
								<div class="block" id="${modalId}" data-modal-html="${modalId}">
									<div class="pre-loader" style="margin-top:15px;">
										<svg class="pre-loader__circular">
											<circle
												class="pre-loader__path"
												cx="50"
												cy="50"
												r="20"
												fill="none"
												stroke="white"
												stroke-width="3"
												stroke-miterlimit="10"
											/>
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			`;

			// Function on modal start opened
			settings.onStart.call();

			// Prepend content
			$('body').append(dBox);

			// Get current dark box container
			let darkBoxContainer = $('[data-dark-box-id="' + modalId + '"]');

			// Function on modal complete opened
			settings.onComplete.call();

			darkBoxContainer.fadeIn();

			// Add class to final modal
			darkBoxContainer.find('.modal').addClass(settings.actionClass);

			// Open modal
			darkBoxContainer.find('.modal').addClass(settings.completeClass);

			// CSRF
			$.ajaxSetup({
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				}
			});

			// Running and loading ajax
			$.ajax({
				url: settings.for,
				type: settings.method,
				data: settings.params,
				dataType: 'json',
				async: true,
				success: function(result) {
					$('[data-modal-html="' + modalId + '"]').html(result.data);
					console.log('Results appended to #' + modalId + ' container');
				}
			});
		} else {
			// Open dark box
			modalItem.closest('.dark-box').fadeIn(settings.fadeInTime, function() {
				console.log('Dark box is on');

				// Open modal
				modalItem.addClass(settings.actionClass);

				console.log('Modal is on');
			});

			// Function on modal complete opened
			settings.onComplete.call();

			// Add class to final modal
			modalItem.addClass(settings.completeClass);

			console.log('Modal open complete');
		}

		// Add close btn function
		$('[data-modal-close]').on('click', function() {
			$(this).closeModal(settings);

			if ($(this).data('method') === 'remove') {
				setTimeout(function() {
					$('[data-dark-box-id="' + $(this).data('for') + '"]').remove();
				}, 250);
			}
		});
	};

	// Auto load all data tags
	$('[data-modal]').each(function() {
		$(this).on('click', function() {
			$(this).modal();
		});
	});

});
