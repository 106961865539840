$(function() {

	// Final value generator
	$('#quantity_keyup_change').on('keyup', function () {
		let value = $('#manual_credit_value').val() * $(this).val();
		$('#total_price_keyup_result').val('R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
	});
	$('#manual_credit_value').on('keyup', function () {
		let value = $('#quantity_keyup_change').val() * $(this).val();
		$('#total_price_keyup_result').val('R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
	});

	// Partner add/remove
	$('#area-partners-add').on('click', function () {
		let input = $('#area_partner_select');

		const partner = {
			id: input.val(),
			name: input.find('option:selected').text()
		};
		const partnerContent = `
			<div class="block commission-partner-add">
				<div class="row">
					<div class="col-06">
						<label for="partner_name_${partner.id}" class="form-label">Comissão</label>
						<input type="readonly" class="input" value="${partner.name}" id="partner_name_${partner.id}">
					</div>
					<div class="col-03">
						<label for="partner_commission_${partner.id}" class="form-label">Comissão</label>
						<input type="number" class="input" name="partners[${partner.id}][commission]" value="0" id="partner_commission_${partner.id}">
					</div>
					<div class="col-03">
						<div class="btn block inline-with-input" onclick="this.closest('.commission-partner-add').remove();">Excluir</div>
					</div>
				</div>
			</div>
		`;
		$('#area-partners-container').append(partnerContent);
	});

});
