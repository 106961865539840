require('./bootstrap');

import Alpine from 'alpinejs';
window.Alpine = Alpine;

Alpine.start();

// 3rd party scripts
require('../../node_modules/parsleyjs/dist/parsley.js');
require('../../node_modules/parsleyjs/dist/i18n/pt-br.js');

// Custom JS scripts
require('./scripts/aside.js')
require('./scripts/snackbar.js')
require('./scripts/mask.js')
require('./scripts/dropdown.js')
require('./scripts/modal.js')
require('./scripts/filter-list.js')
require('./scripts/smooth-scroll.js')
require('./scripts/footprint-calc.js')
require('./scripts/media.js')
require('./scripts/form-choice.js')
require('./scripts/sell.js')
