// Popper
window.Popper = require('popper.js').default;

// Lodash
window._ = require('lodash');

// Lodash
window.$ = window.jQuery = require('jquery');

// Axios
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Environment for Service Worker
const $env = process.env.MIX_APP_ENV;
