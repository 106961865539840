$(function() {

	// Dropdown menu
	window.dropdownCollapse = function() {
		$('[data-dropdown]').on('click', function(e) {
			let item = $(this);
			let dropdown = $('[data-collapse="' + item.data('for') + '"]');

			if (item.hasClass('active')) {
				removeDropdowns();
			} else {
				removeDropdowns();
				item.addClass('active');
				dropdown.addClass('active');
			}

			e.stopPropagation();
			e.preventDefault();
		});
	}

	// Ocultando dropdowns
	function removeDropdowns() {
		$('[data-dropdown], [data-collapse]').removeClass('active');
	}

	// Ocultando todos os menus de dropdown
	removeDropdowns();

	// Inicializando função
	dropdownCollapse();

	// Ocultando dropdowns ao clicar fora
	$(document).on('click', function() {
		removeDropdowns();
	});

});
