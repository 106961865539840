$(function() {
	$('#app-aside-nav .aside-dropdown').on('click', function(e) {
		let rootEl = $(this).closest('li');

		if (rootEl.hasClass('active')) {
			$('#app-aside-nav .aside-dropdown').closest('li').removeClass('active');
			$('#app-aside-nav .aside-dropdown-collapse').slideUp('fast');
		} else {
			$('#app-aside-nav .aside-dropdown').closest('li').removeClass('active');
			$('#app-aside-nav .aside-dropdown-collapse').slideUp('fast');
			rootEl.addClass('active');
			rootEl.find('.aside-dropdown-collapse').slideDown('fast');
		}

		e.preventDefault();
	});

	$('#app-aside-nav a').each(function() {
		let currentURL = window.location.href;
		if (!$(this).hasClass('aside-dropdown') && $(this).attr('href') === currentURL) {
			$(this).addClass('current');
		}
	});

	$('#aside-pull').on('click', function(e) {
		$('#app-aside').toggleClass('opened');
		e.preventDefault();
	});

	$(document).mouseup(function(e) {
		let container = $('#aside');

		if (!container.is(e.target) && container.has(e.target).length === 0) {
			container.removeClass('opened');
		}
	});
});
