$(function() {

	$('[data-smooth-scroll]').on('click', function(e) {
		e.preventDefault();

 		if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
 			location.hostname === this.hostname) {

			let target = $(this.hash);

			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

			if (target.length) {
				event.preventDefault();

				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000, function() {
					const $target = $(target);

					$target.focus();

					if ($target.is(":focus")) {
						return false;
					} else {
						$target.attr('tabindex', '-1');
						$target.focus();
					}
				});
			}
		}
	});

});
