$(function() {

	// Check if media item is mark as checked
	if ($('#mediaModalWrapper').length) {
		const mediaModalWrapper = $('#mediaModalWrapper');
		const mediaItem = mediaModalWrapper.find('[data-media-item]');
		const deleteMediaBtn = $('#mediaDeleteSelected');
		const selectMediaBtn = $('#mediaAddSelected');

		// Select media function
		$.fn.checkMediaItemChecked = function() {
			const $this = $(this);

			if ($('#mediaModalWrapper [data-media-item]:checked').length > 0) {
				// Show delete box
				$('#mediaDeleteSelected').removeClass('disabled');

				// Can I choose one, only?
				if ($('#selectMultiple').val() == 'none') {
					$('[data-media-item]').prop('checked', false);
					$this.prop('checked', true);
				}

				if ($('#selectTo').val() !== 'none') {
					$('#mediaAddSelected').css({'display' : 'block'});
				}

				console.log('Enabling Delete Media Button');
			} else {
				// Remove delete box
				$('#mediaDeleteSelected').addClass('disabled');
				console.log('Disabling Delete Media Button');
				$('#mediaAddSelected').css({'display' : 'none'});
			}
		}

		// Select media
		$('[data-media-item]').on('click', function() {
			$(this).checkMediaItemChecked();
		});

		// Add media to block or gallery
		$('#mediaAddSelected').on('click', function(e) {
			console.log('Media Add Clicked!');

			const name = $('#selectTo').val();

			const $files = [];

			$('[data-media-item]:checked').each(function() {
				$files.push($(this).val());
			});

			const filesGroup = $files.length > 0 ? $files.join(';') : '';

			$('[data-media-group="' + name + '"]').val(filesGroup);

			console.log('Added to media group named [' + name + '] the content: ' + filesGroup);

			let displayCounter;
			let newSrc;

			if ($('#selectMultiple').val() === 'none' && $files.length > 0) {
				newSrc = $('meta[name="media_url"]').prop('content') + '/' + ($files[0]);
				$('[data-media-preview="' + name + '"]').prop('src', newSrc);

				console.log('Image ' + newSrc + ' added to image src #id ' + name);
			} else {
				displayCounter = $('[data-media-album-count="' + name + '"]');
				if (displayCounter.length) {
					if ($files.length > 0) {
						displayCounter.text($files.length);
						$('[data-album-overlay-1="' + name + '"]').css('display', 'block');

						if ($files.length > 1) {
							$('[data-album-overlay-2="' + name + '"]').css('display', 'block');
						} else {
							$('[data-album-overlay-2="' + name + '"]').css('display', 'none');
						}

						if ($files.length > 2) {
							$('[data-album-overlay-3="' + name + '"]').css('display', 'block');
						} else {
							$('[data-album-overlay-3="' + name + '"]').css('display', 'none');
						}

						if ($files.length > 3) {
							$('[data-album-overlay-4="' + name + '"]').css('display', 'block');
						} else {
							$('[data-album-overlay-4="' + name + '"]').css('display', 'none');
						}

						if ($files.length > 4) {
							$('[data-album-overlay-5="' + name + '"]').css('display', 'block');
						} else {
							$('[data-album-overlay-5="' + name + '"]').css('display', 'none');
						}
					} else {
						displayCounter.text('Vazio');
						$('[data-album-overlay-1="' + name + '"], [data-album-overlay-2="' + name + '"], [data-album-overlay-3="' + name + '"], [data-album-overlay-4="' + name + '"], [data-album-overlay-5="' + name + '"]').css('display', 'none');
					}
				}
			}

			mediaModalClose();

			snackBarClear();
			SnackBarAdd('Mídia adicionada com sucesso');
			snackBar();

			e.preventDefault();
		});

		// Open Modal
		$('[data-media-modal-open]').each(function(e) {
			$(this).on('click', function(e) {
				const setElement = $(this).data('select');
				const setMultiple = $(this).data('multiple');

				$('[data-media-item]').prop('checked', false);

				if (setElement && setElement.length) {
					$('#selectTo').val(setElement);

					console.log('Select to: ' + setElement);
					console.log('Select Multiple: ' + setMultiple);

					const mediaGroupEl = $('[data-media-group="' + setElement + '"');
					const mediaGroup = mediaGroupEl.length ? mediaGroupEl.val() : '';

					if (mediaGroup.length > 0 && mediaGroup.includes(';')) {
						const mediaFiles = mediaGroup.split(";");

						$('[data-media-item]').each(function() {
							let $thisItem = $(this);

							$.each(mediaFiles, function(i) {
								if ($thisItem.val() === mediaFiles[i]) {
									$thisItem.prop('checked', true);
								}
							})
						});
					}
				}

				if (setMultiple && setMultiple.length) {
					$('#selectMultiple').val(setMultiple);
					console.log('Select multiple: ' + setMultiple);
				}

				$('#mediaModalWrapper').fadeIn();
				e.preventDefault();
			});
		});

		// Click to select all
		$('[data-select-all-media]').on('click', function(e) {
			if ($('#selectMultiple').val() === 'true') {
				$('[data-media-item]').prop('checked', true);
				$('#mediaAddSelected').show();
			} else {
				snackBarClear();
				SnackBarAdd('Neste vínculo só é possível escolher uma mídia.');
				snackBar();
			}
			e.preventDefault();
		});

		// Close modal
		$('#mediaModalClose').on('click', function(e) {
			mediaModalClose();
			e.preventDefault();
		});

		window.mediaModalClose = function() {
			$('#mediaModalWrapper').fadeOut('fast');
			$('[data-media-item]').prop('checked', false);
			$('#selectTo').val('none');
			$('#selectMultiple').val('true');
		}

		// Reset media block
		$('[data-reset-media-block]').on('click', function() {
			const imgFor = $(this).data('for');
			const imgDest = $('[data-media-preview="' + imgFor + '"]');

			imgDest.prop('src', imgDest.data('empty'));
			$('[data-media-group="' + imgFor + '"]').val('');

			console.log('Media Block [' + imgFor + '] reseted');
		});

		// Select files
		$('#mediaUploadBtn').on('click', function(e) {
			$('#mediaInput').click();
			e.preventDefault();
		});

		// Form button
		$('#mediaInput').on('change', function(e) {
			const inputLength = $(this).get(0).files.length;

			if (inputLength === 0) {
				$('#mediaUploadSubmit').html($(this).data('fill-empty')).hide();
			} else {
				$('#mediaUploadSubmit').html($(this).data('fill-first') + ' ' + inputLength + ' ' + $(this).data('fill-last')).show();
			}

			e.preventDefault();
		});

		// Submit form
		$('#mediaUploadSubmit').on('click', function(e) {
			$('#mediaUpload').submit();
		});

		// Delete multiple medias
		$('#mediaDeleteSelected').on('click', function() {
			const $files = [];

			$('[data-media-item]:checked').each(function() {
				$files.push($(this).val());
			});

			if ($files.length > 0)
			{
				const insertItems = $files.join('; ');

				$('#deleteMediaFiles').val(insertItems);
				$('#mediaModalDeleteQuantity').html($files.length);

				console.log('Files to be excluded:' + insertItems);
			}
		});

		// Upload file
		$('#mediaUpload').on('submit', function(e) {
			$('#mediaPreLoader').addClass('active');
		});
	}

});
